import { AddBlock } from "../../../../src/components/Blocks/common/statik/AddBlock/AddBlock";
import { SettingsAdvanced } from "../../../../src/components/Blocks/common/statik/Settings/components/SettingsAdvanced";
import { Toolbar } from "../../../../src/components/Blocks/common/statik/Toolbar/Toolbar";
import { SettingsLayout } from "../../../../src/components/Blocks/common/statik/Settings/components/SettingsLayout";
import TimelineAddBlockImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Timeline/timeline-how-to-add-block.png";
import TimelineAddBlockParagraphImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Timeline/timeline-how-to-add-block-paragraph.png";
import TimelineToolbarImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Timeline/timeline-toolbar.png";
import TimelineToolbarDragImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Timeline/timeline-toolbar-drag.png";
import TimelineToolbarMoveImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Timeline/timeline-toolbar-move.png";
import TimelineToolbarOptionsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Timeline/timeline-toolbar-options.png";
import TimelineToolbarTransformImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Timeline/timeline-toolbar-transform.png";
import TimelineItemToolbarImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Timeline/timeline-item-toolbar.png";
import TimelineItemToolbarDragImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Timeline/timeline-item-toolbar-drag.png";
import TimelineItemToolbarSelectTimelineImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Timeline/timeline-item-toolbar-select-timeline.png";
import TimelineItemToolbarHorizontalAlignImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Timeline/timeline-item-toolbar-horizontal-align.png";
import TimelineItemToolbarMoveImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Timeline/timeline-item-toolbar-move.png";
import TimelineItemToolbarOptionsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Timeline/timeline-item-toolbar-options.png";
import TimelineItemToolbarBoldImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Timeline/timeline-item-toolbar-bold.png";
import TimelineItemToolbarMoreImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Timeline/timeline-item-toolbar-more.png";
import TimelineItemToolbarItalicsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Timeline/timeline-item-toolbar-italics.png";
import TimelineGroupToolbarImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Timeline/timeline-group-toolbar.png";
import TimelineGroupToolbarDragImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Timeline/timeline-group-toolbar-drag.png";
import TimelineGroupToolbarOptionsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Timeline/timeline-group-toolbar-options.png";
import TimelineGroupToolbarSelectItemImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Timeline/timeline-group-toolbar-select-item.png";
import TimelineGroupToolbarTransformImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Timeline/timeline-group-toolbar-transform.png";
import SettingsBorderImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/common/settings-border.png";
import SettingsColorImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/common/settings-color.png";
import SettingsDimensionsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/common/settings-dimensions.png";
import SettingsFontVariationsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/common/settings-font-variations.png";
import SettingsTypographyImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/common/settings-typography.png";
import * as React from 'react';
export default {
  AddBlock,
  SettingsAdvanced,
  Toolbar,
  SettingsLayout,
  TimelineAddBlockImage,
  TimelineAddBlockParagraphImage,
  TimelineToolbarImage,
  TimelineToolbarDragImage,
  TimelineToolbarMoveImage,
  TimelineToolbarOptionsImage,
  TimelineToolbarTransformImage,
  TimelineItemToolbarImage,
  TimelineItemToolbarDragImage,
  TimelineItemToolbarSelectTimelineImage,
  TimelineItemToolbarHorizontalAlignImage,
  TimelineItemToolbarMoveImage,
  TimelineItemToolbarOptionsImage,
  TimelineItemToolbarBoldImage,
  TimelineItemToolbarMoreImage,
  TimelineItemToolbarItalicsImage,
  TimelineGroupToolbarImage,
  TimelineGroupToolbarDragImage,
  TimelineGroupToolbarOptionsImage,
  TimelineGroupToolbarSelectItemImage,
  TimelineGroupToolbarTransformImage,
  SettingsBorderImage,
  SettingsColorImage,
  SettingsDimensionsImage,
  SettingsFontVariationsImage,
  SettingsTypographyImage,
  React
};