import React from "react";
import { Wrapper } from "./CoreBlock.style";

export const CoreBlock = (props) => {
  const { developerHref, articleHref, label } = props;

  return (
    <Wrapper>
      <h2>{label}</h2>
      <a href={developerHref}>Developer Resource</a>
      <a href={articleHref}>Block article</a>
    </Wrapper>
  );
};
