import React from "react";
import { WrapperImageSmall, Wrapper } from "../../../wrappers/Wrappers.style";
import {
  htmlAnchor,
  modifyQuery,
  cssClasses,
  linkRel,
  htmlEl,
} from "./SettingsAdvancedDescription";
import SettingsAdvancedWithAnchorImage from "../../../../../../../content/Statik JAM Hosting/3. Blocks/images/common/settings-advanced-with-anchor.png";
import SettingsAdvancedWithAnchorLinkRelImage from "../../../../../../../content/Statik JAM Hosting/3. Blocks/images/common/settings-advanced-with-anchor-link-rel.png";
import SettingsAdvancedWithAnchorHtmlElImage from "../../../../../../../content/Statik JAM Hosting/3. Blocks/images/common/settings-advanced-with-anchor-html-element.png";
import SettingsAdvancedWithoutAnchorImage from "../../../../../../../content/Statik JAM Hosting/3. Blocks/images/common/settings-advanced-without-anchor.png";
import SettingsAdvancedWithModifyQuery from "../../../../../../../content/Statik JAM Hosting/3. Blocks/images/common/settings-advanced-with-modify-query.png";
import SettingsAdvancedAdditionalCss from "../../../../../../../content/Statik JAM Hosting/3. Blocks/images/common/settings-advanced-additional-css.png";

export const SettingsAdvanced = (props) => {
  const { advancedType } = props;

  const advancedContent = () => {
    switch (advancedType) {
      case "htmlEl":
        return (
          <>
            <WrapperImageSmall>
              <img
                alt="Advanced settings"
                src={SettingsAdvancedWithAnchorHtmlElImage}
              />
            </WrapperImageSmall>
            <>
              {htmlEl()} {htmlAnchor()} {cssClasses()}
            </>
          </>
        );
      case "linkRel":
        return (
          <>
            <WrapperImageSmall>
              <img
                alt="Advanced settings"
                src={SettingsAdvancedWithAnchorLinkRelImage}
              />
            </WrapperImageSmall>
            <>
              {linkRel()} {htmlAnchor()} {cssClasses()}
            </>
          </>
        );
      case "htmlAnchor":
        return (
          <>
            <WrapperImageSmall>
              <img
                alt="Advanced settings"
                src={SettingsAdvancedWithAnchorImage}
              />
            </WrapperImageSmall>
            <>
              {htmlAnchor()} {cssClasses()}
            </>
          </>
        );

      case "modifyQuery": {
        return (
          <>
            <WrapperImageSmall>
              <img
                alt="Advanced settings"
                src={SettingsAdvancedWithModifyQuery}
              />
            </WrapperImageSmall>
            {modifyQuery()} {cssClasses()}
          </>
        );
      }
      case "additionalCss": {
        return (
          <>
            <WrapperImageSmall>
              <img
                alt="Advanced settings"
                src={SettingsAdvancedAdditionalCss}
              />
            </WrapperImageSmall>
            {cssClasses()}
          </>
        );
      }
      default: {
        return (
          <>
            <WrapperImageSmall>
              <img
                alt="Advanced settings"
                src={SettingsAdvancedWithoutAnchorImage}
              />
            </WrapperImageSmall>
            {cssClasses()}
          </>
        );
      }
    }
  };

  return (
    <Wrapper>
      <p>
        The <b>Advanced</b> tab lets you add optional settings to your Block.
      </p>
      {advancedContent()}
    </Wrapper>
  );
};
