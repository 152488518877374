import React from "react";
import { WrapperImageSmall, Wrapper } from "../../wrappers/Wrappers.style";

export const Toolbar = (props) => {
  const {
    toolbarImage,
    toolbarIntroductionText,
    toolbarDescription,
    minimizedImage = true,
  } = props;

  return (
    <Wrapper>
      {toolbarIntroductionText}
      {minimizedImage ? (
        <WrapperImageSmall>
          <img src={toolbarImage} />
        </WrapperImageSmall>
      ) : (
        <img src={toolbarImage} />
      )}

      {toolbarDescription}
    </Wrapper>
  );
};
