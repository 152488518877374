import React from "react";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;

  b {
    text-transform: capitalize;
  }
`;

export const WrapperImageSmall = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 50%;
  }
`;

export const WrapperDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
`;
