import { AddBlock } from "../../../../src/components/Blocks/common/statik/AddBlock/AddBlock";
import { SettingsAdvanced } from "../../../../src/components/Blocks/common/statik/Settings/components/SettingsAdvanced";
import { Settings } from "../../../../src/components/Blocks/common/statik/Settings/Settings";
import { Toolbar } from "../../../../src/components/Blocks/common/statik/Toolbar/Toolbar";
import SpacerAddBlockImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Spacer/spacer-how-to-add-block.png";
import SpacerAddBlockParagraphImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Spacer/spacer-how-to-add-block-paragraph.png";
import SpacerSettingsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Spacer/spacer-settings.png";
import SpacerToolbarImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Spacer/spacer-toolbar.png";
import SpacerToolbarDragImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Spacer/spacer-toolbar-drag.png";
import SpacerToolbarMoveImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Spacer/spacer-toolbar-move.png";
import SpacerToolbarOptionsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Spacer/spacer-toolbar-options.png";
import SpacerToolbarTransformImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Spacer/spacer-toolbar-transform.png";
import * as React from 'react';
export default {
  AddBlock,
  SettingsAdvanced,
  Settings,
  Toolbar,
  SpacerAddBlockImage,
  SpacerAddBlockParagraphImage,
  SpacerSettingsImage,
  SpacerToolbarImage,
  SpacerToolbarDragImage,
  SpacerToolbarMoveImage,
  SpacerToolbarOptionsImage,
  SpacerToolbarTransformImage,
  React
};