import { AddBlock } from "../../../../src/components/Blocks/common/statik/AddBlock/AddBlock";
import { SettingsAdvanced } from "../../../../src/components/Blocks/common/statik/Settings/components/SettingsAdvanced";
import { Settings } from "../../../../src/components/Blocks/common/statik/Settings/Settings";
import { Toolbar } from "../../../../src/components/Blocks/common/statik/Toolbar/Toolbar";
import { SettingsLayout } from "../../../../src/components/Blocks/common/statik/Settings/components/SettingsLayout";
import TabsAddBlockImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Tabs/tabs-how-to-add-block.png";
import TabsAddBlockParagraphImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Tabs/tabs-how-to-add-block-paragraph.png";
import TabsToolbarImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Tabs/tab-toolbar.png";
import TabsToolbarAddNewTabImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Tabs/tabs-toolbar-add-new-tab.png";
import TabsToolbarContainerSizeImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Tabs/tabs-toolbar-container-size.png";
import TabsToolbarDragImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Tabs/tabs-toolbar-drag.png";
import TabsToolbarMoveImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Tabs/tabs-toolbar-move.png";
import TabsToolbarOptionsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Tabs/tabs-toolbar-options.png";
import TabsToolbarSelectImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Tabs/tabs-toolbar-select.png";
import TabsToolbarTransformImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Tabs/tabs-toolbar-transform.png";
import TabsSettingsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Tabs/tabs-settings.png";
import TabsSettingsContentImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Tabs/tabs-settings-content.png";
import TabsSettingsSelectorImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Tabs/tabs-settings-selector.png";
import TabsSettingsSelector2Image from "../../../../content/Statik JAM Hosting/3. Blocks/images/Tabs/tabs-settings-selector-2.png";
import TabToolbarImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Tabs/tab-toolbar.png";
import SettingsBorderImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/common/settings-border.png";
import SettingsColorImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/common/settings-color.png";
import SettingsDimensionsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/common/settings-dimensions.png";
import SettingsFontVariationsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/common/settings-font-variations.png";
import SettingsTypographyImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/common/settings-typography.png";
import * as React from 'react';
export default {
  AddBlock,
  SettingsAdvanced,
  Settings,
  Toolbar,
  SettingsLayout,
  TabsAddBlockImage,
  TabsAddBlockParagraphImage,
  TabsToolbarImage,
  TabsToolbarAddNewTabImage,
  TabsToolbarContainerSizeImage,
  TabsToolbarDragImage,
  TabsToolbarMoveImage,
  TabsToolbarOptionsImage,
  TabsToolbarSelectImage,
  TabsToolbarTransformImage,
  TabsSettingsImage,
  TabsSettingsContentImage,
  TabsSettingsSelectorImage,
  TabsSettingsSelector2Image,
  TabToolbarImage,
  SettingsBorderImage,
  SettingsColorImage,
  SettingsDimensionsImage,
  SettingsFontVariationsImage,
  SettingsTypographyImage,
  React
};