import { AddBlock } from "../../../../src/components/Blocks/common/statik/AddBlock/AddBlock";
import { SettingsAdvanced } from "../../../../src/components/Blocks/common/statik/Settings/components/SettingsAdvanced";
import { Toolbar } from "../../../../src/components/Blocks/common/statik/Toolbar/Toolbar";
import VideoAddBlockImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Video/video-how-to-add-block.png";
import VideoAddBlockParagraphImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Video/video-how-to-add-block-paragraph.png";
import VideoToolbarImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Video/video-toolbar.png";
import VideoToolbarTransformImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Video/video-toolbar-transform.png";
import VideoToolbarDragImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Video/video-toolbar-drag.png";
import VideoToolbarMoveImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Video/video-toolbar-move.png";
import VideoToolbarAlignImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Video/video-toolbar-align.png";
import VideoToolbarOptionsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Video/video-toolbar-options.png";
import * as React from 'react';
export default {
  AddBlock,
  SettingsAdvanced,
  Toolbar,
  VideoAddBlockImage,
  VideoAddBlockParagraphImage,
  VideoToolbarImage,
  VideoToolbarTransformImage,
  VideoToolbarDragImage,
  VideoToolbarMoveImage,
  VideoToolbarAlignImage,
  VideoToolbarOptionsImage,
  React
};