import { AddBlock } from "../../../../src/components/Blocks/common/statik/AddBlock/AddBlock";
import { SettingsAdvanced } from "../../../../src/components/Blocks/common/statik/Settings/components/SettingsAdvanced";
import { Settings } from "../../../../src/components/Blocks/common/statik/Settings/Settings";
import { Toolbar } from "../../../../src/components/Blocks/common/statik/Toolbar/Toolbar";
import SliderAddBlockImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Slider/slider-how-to-add-block.png";
import SliderAddBlockParagraphImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Slider/slider-how-to-add-block-paragraph.png";
import SliderSettingsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Slider/slider-settings.png";
import SliderToolbarImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Slider/slider-toolbar.png";
import SliderToolbarAddNewSlideImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Slider/slider-toolbar-add-new-slide.png";
import SliderToolbarAlignImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Slider/slider-toolbar-align.png";
import SliderToolbarDragImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Slider/slider-toolbar-drag.png";
import SliderToolbarMoveImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Slider/slider-toolbar-move.png";
import SliderToolbarOptionsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Slider/slider-toolbar-options.png";
import SliderToolbarSelectSlideImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Slider/slider-toolbar-select-slide.png";
import SliderToolbarTransformImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Slider/slider-toolbar-transform.png";
import * as React from 'react';
export default {
  AddBlock,
  SettingsAdvanced,
  Settings,
  Toolbar,
  SliderAddBlockImage,
  SliderAddBlockParagraphImage,
  SliderSettingsImage,
  SliderToolbarImage,
  SliderToolbarAddNewSlideImage,
  SliderToolbarAlignImage,
  SliderToolbarDragImage,
  SliderToolbarMoveImage,
  SliderToolbarOptionsImage,
  SliderToolbarSelectSlideImage,
  SliderToolbarTransformImage,
  React
};