import React from "react";
import AddBlockButtonImage from "../../../../../../content/Statik JAM Hosting/3. Blocks/images/common/how-to-add-block.png";
import { WrapperImageSmall, Wrapper } from "../../wrappers/Wrappers.style";

export const AddBlock = (props) => {
  const { addBlockImage, label, addBlockParagraphImage } = props;

  return (
    <Wrapper>
      <p>
        To add <b>{label} block</b> to your page, click on the{" "}
        <b>Block Inserter</b> icon.
      </p>
      <img src={AddBlockButtonImage} />
      <p>
        The next step is to type <b>{label}</b> into search input field.
      </p>
      <img src={addBlockImage} />
      <p>
        Alternatively, you can use the slash command /{label} in a new paragraph
        block then press enter to add one quickly.
      </p>
      <WrapperImageSmall>
        <img src={addBlockParagraphImage} />
      </WrapperImageSmall>
      <a href="src/components/Blocks/common/statik/AddBlock/AddBlock.js">
        Detailed instructions on adding block (WordPress documentation)
      </a>
    </Wrapper>
  );
};
