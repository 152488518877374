import { AddBlock } from "../../../../src/components/Blocks/common/statik/AddBlock/AddBlock";
import { SettingsAdvanced } from "../../../../src/components/Blocks/common/statik/Settings/components/SettingsAdvanced";
import { Settings } from "../../../../src/components/Blocks/common/statik/Settings/Settings";
import { Toolbar } from "../../../../src/components/Blocks/common/statik/Toolbar/Toolbar";
import VideoButtonsAddBlockImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-buttons-how-to-add-block.png";
import VideoButtonsAddBlockParagraphImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-buttons-how-to-add-block-paragraph.png";
import VideoButtonsSettingsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-buttons-settings.png";
import VideoButtonsSettingsDimensionsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-buttons-settings-dimensions.png";
import VideoButtonsToolbarImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-toolbar.png";
import VideoButtonsToolbarContainerSizeImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-buttons-toolbar-container-size.png";
import VideoButtonsToolbarDragImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-buttons-toolbar-drag.png";
import VideoButtonsToolbarJustifyImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-buttons-toolbar-justify.png";
import VideoButtonsToolbarMoveImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-buttons-toolbar-move.png";
import VideoButtonsToolbarOptionsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-buttons-toolbar-options.png";
import VideoButtonsToolbarTransformImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-buttons-toolbar-transform.png";
import VideoButtonsToolbarVerticalAlignImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-buttons-toolbar-vertical-align.png";
import VideoButtonToolbarImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-toolbar.png";
import VideoButtonToolbarAlignImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-toolbar-align.png";
import VideoButtonToolbarBoldImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-toolbar-bold.png";
import VideoButtonToolbarContainerSizeImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-toolbar-container-size.png";
import VideoButtonToolbarDragImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-toolbar-drag.png";
import VideoButtonToolbarItalicImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-toolbar-italic.png";
import VideoButtonToolbarJustifyImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-toolbar-justify.png";
import VideoButtonToolbarLinkImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-toolbar-link.png";
import VideoButtonToolbarMoreImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-toolbar-more.png";
import VideoButtonToolbarMoveImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-toolbar-move.png";
import VideoButtonToolbarOptionsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-toolbar-options.png";
import VideoButtonToolbarSelectButtonsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-toolbar-select-buttons.png";
import VideoButtonToolbarStylesImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-toolbar-styles.png";
import VideoButtonToolbarVerticalAlignImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-toolbar-vertical-align.png";
import VideoButtonSettingsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-settings.png";
import VideoButtonSettingsBorderImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-settings-border.png";
import VideoButtonSettingsColorImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-settings-color.png";
import VideoButtonSettingsDimensionsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-settings-dimensions.png";
import VideoButtonSettingsFontVariationsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-settings-font-variations.png";
import VideoButtonSettingsTypographyImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-settings-typography.png";
import VideoButtonSettingsWidthImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/VideoButtons/video-button-settings-width.png";
import * as React from 'react';
export default {
  AddBlock,
  SettingsAdvanced,
  Settings,
  Toolbar,
  VideoButtonsAddBlockImage,
  VideoButtonsAddBlockParagraphImage,
  VideoButtonsSettingsImage,
  VideoButtonsSettingsDimensionsImage,
  VideoButtonsToolbarImage,
  VideoButtonsToolbarContainerSizeImage,
  VideoButtonsToolbarDragImage,
  VideoButtonsToolbarJustifyImage,
  VideoButtonsToolbarMoveImage,
  VideoButtonsToolbarOptionsImage,
  VideoButtonsToolbarTransformImage,
  VideoButtonsToolbarVerticalAlignImage,
  VideoButtonToolbarImage,
  VideoButtonToolbarAlignImage,
  VideoButtonToolbarBoldImage,
  VideoButtonToolbarContainerSizeImage,
  VideoButtonToolbarDragImage,
  VideoButtonToolbarItalicImage,
  VideoButtonToolbarJustifyImage,
  VideoButtonToolbarLinkImage,
  VideoButtonToolbarMoreImage,
  VideoButtonToolbarMoveImage,
  VideoButtonToolbarOptionsImage,
  VideoButtonToolbarSelectButtonsImage,
  VideoButtonToolbarStylesImage,
  VideoButtonToolbarVerticalAlignImage,
  VideoButtonSettingsImage,
  VideoButtonSettingsBorderImage,
  VideoButtonSettingsColorImage,
  VideoButtonSettingsDimensionsImage,
  VideoButtonSettingsFontVariationsImage,
  VideoButtonSettingsTypographyImage,
  VideoButtonSettingsWidthImage,
  React
};