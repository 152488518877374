import { AddBlock } from "../../../../src/components/Blocks/common/statik/AddBlock/AddBlock";
import { SettingsAdvanced } from "../../../../src/components/Blocks/common/statik/Settings/components/SettingsAdvanced";
import { Settings } from "../../../../src/components/Blocks/common/statik/Settings/Settings";
import { Toolbar } from "../../../../src/components/Blocks/common/statik/Toolbar/Toolbar";
import IconsAddBlockImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icons/icons-how-to-add-block.png";
import IconsAddBlockParagraphImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icons/icons-how-to-add-block-paragraph.png";
import IconsSettingsTransformImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icons/icons-settings-transform.png";
import IconsToolbarImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icons/icons-toolbar.png";
import IconsToolbarAlignImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icons/icons-toolbar-align.png";
import IconsToolbarDragImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icons/icons-toolbar-drag.png";
import IconsToolbarJustifyImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icons/icons-toolbar-justify.png";
import IconsToolbarMoveImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icons/icons-toolbar-move.png";
import IconsToolbarOptionsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icons/icons-toolbar-options.png";
import IconsToolbarTransformImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icons/icons-toolbar-transform.png";
import * as React from 'react';
export default {
  AddBlock,
  SettingsAdvanced,
  Settings,
  Toolbar,
  IconsAddBlockImage,
  IconsAddBlockParagraphImage,
  IconsSettingsTransformImage,
  IconsToolbarImage,
  IconsToolbarAlignImage,
  IconsToolbarDragImage,
  IconsToolbarJustifyImage,
  IconsToolbarMoveImage,
  IconsToolbarOptionsImage,
  IconsToolbarTransformImage,
  React
};