import React from "react";
import { WrapperDescription } from "../../wrappers/Wrappers.style";

export const Description = (props) => {
  const { children, blockImage } = props;
  return (
    <WrapperDescription>
      <img src={blockImage} />
      <p>{children}</p>
    </WrapperDescription>
  );
};
