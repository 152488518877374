import { AddBlock } from "../../../../src/components/Blocks/common/statik/AddBlock/AddBlock";
import { SettingsAdvanced } from "../../../../src/components/Blocks/common/statik/Settings/components/SettingsAdvanced";
import { Settings } from "../../../../src/components/Blocks/common/statik/Settings/Settings";
import { Toolbar } from "../../../../src/components/Blocks/common/statik/Toolbar/Toolbar";
import IconAddBlockImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icon/icon-how-to-add-block.png";
import IconAddBlockParagraphImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icon/icon-how-to-add-block-paragraph.png";
import IconSettingsStyleImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icon/icon-settings-styles.png";
import IconSettingsColor from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icon/icon-settings-color.png";
import IconSettingsDimensions from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icon/icon-settings-dimensions.png";
import IconToolbarImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icon/icon-toolbar.png";
import IconToolbarDragImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icon/icon-toolbar-drag.png";
import IconToolbarMoveImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icon/icon-toolbar-move.png";
import IconToolbarOptionsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icon/icon-toolbar-options.png";
import IconToolbarTransformImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icon/icon-toolbar-transform.png";
import IconToolbarAlignImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icon/icon-toolbar-align.png";
import IconToolbarReplaceImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Icon/icon-toolbar-replace.png";
import * as React from 'react';
export default {
  AddBlock,
  SettingsAdvanced,
  Settings,
  Toolbar,
  IconAddBlockImage,
  IconAddBlockParagraphImage,
  IconSettingsStyleImage,
  IconSettingsColor,
  IconSettingsDimensions,
  IconToolbarImage,
  IconToolbarDragImage,
  IconToolbarMoveImage,
  IconToolbarOptionsImage,
  IconToolbarTransformImage,
  IconToolbarAlignImage,
  IconToolbarReplaceImage,
  React
};