import { AddBlock } from "../../../../src/components/Blocks/common/statik/AddBlock/AddBlock";
import { SettingsAdvanced } from "../../../../src/components/Blocks/common/statik/Settings/components/SettingsAdvanced";
import { Settings } from "../../../../src/components/Blocks/common/statik/Settings/Settings";
import { Toolbar } from "../../../../src/components/Blocks/common/statik/Toolbar/Toolbar";
import { Description } from "../../../../src/components/Blocks/common/statik/Description/Description";
import GoogleMapAddBlockImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/GoogleMap/google-map-how-to-add-block.png";
import GoogleMapAddBlockParagraphImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/GoogleMap/google-map-how-to-add-block-paragraph.png";
import GoogleMapSettingsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/GoogleMap/google-map-settings.png";
import GoogleMapToolbarImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/GoogleMap/google-map-toolbar.png";
import GoogleMapToolbarDragImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/GoogleMap/google-map-tooblar-drag.png";
import GoogleMapToolbarAlignImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/GoogleMap/google-map-toolbar-align.png";
import GoogleMapToolbarMoveImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/GoogleMap/google-map-toolbar-move.png";
import GoogleMapToolbarOptionsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/GoogleMap/google-map-toolbar-options.png";
import GoogleMapToolbarTransformImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/GoogleMap/google-map-toolbar-transform.png";
import * as React from 'react';
export default {
  AddBlock,
  SettingsAdvanced,
  Settings,
  Toolbar,
  Description,
  GoogleMapAddBlockImage,
  GoogleMapAddBlockParagraphImage,
  GoogleMapSettingsImage,
  GoogleMapToolbarImage,
  GoogleMapToolbarDragImage,
  GoogleMapToolbarAlignImage,
  GoogleMapToolbarMoveImage,
  GoogleMapToolbarOptionsImage,
  GoogleMapToolbarTransformImage,
  React
};