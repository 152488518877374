import React from "react";
import GroupLayoutImage from "../../../../../../../content/Statik JAM Hosting/3. Blocks/images/common/settings-group-layout.png";
import RowLayoutImage from "../../../../../../../content/Statik JAM Hosting/3. Blocks/images/common/settings-row-layout.png";
import StackLayoutImage from "../../../../../../../content/Statik JAM Hosting/3. Blocks/images/common/settings-stack-layout.png";
import { Toolbar } from "../../Toolbar/Toolbar";

export const SettingsLayout = (props) => {
  const { layoutType } = props;

  const settingsContainer = () => {
    switch (layoutType) {
      case "row": {
        return (
          <Toolbar
            toolbarImage={RowLayoutImage}
            toolbarDescription={
              <ul>
                <li>
                  <b>Inner Blocks use content width</b> - opis
                </li>
                <li>
                  <b>Content</b> - opis
                </li>
                <li>
                  <b>Wide</b> - opis
                </li>
                <li>
                  <b>Justification</b> - opis
                </li>
              </ul>
            }
          />
        );
      }
      case "stack":
        return (
          <Toolbar
            toolbarImage={StackLayoutImage}
            toolbarDescription={
              <ul>
                <li>
                  <b>Justification</b> - opis
                </li>
                <li>
                  <b>Orientation</b> - opis
                </li>
                <li>
                  <b>Allow to wrap to multiple lines</b> - opis
                </li>
              </ul>
            }
          />
        );
      default:
        return (
          <Toolbar
            toolbarImage={GroupLayoutImage}
            toolbarDescription={
              <ul>
                <li>
                  <b>Inner Blocks use content width</b> - opis
                </li>
                <li>
                  <b>Content</b> - opis
                </li>
                <li>
                  <b>Width</b> - opis
                </li>
                <li>
                  <b>Justification</b> - opis
                </li>
              </ul>
            }
          />
        );
    }
  };

  return settingsContainer();
};
