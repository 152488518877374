import { AddBlock } from "../../../../src/components/Blocks/common/statik/AddBlock/AddBlock";
import { SettingsAdvanced } from "../../../../src/components/Blocks/common/statik/Settings/components/SettingsAdvanced";
import { Settings } from "../../../../src/components/Blocks/common/statik/Settings/Settings";
import { Toolbar } from "../../../../src/components/Blocks/common/statik/Toolbar/Toolbar";
import PostTypeAddBlockImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/PostType/post-type-how-to-add-block.png";
import PostTypeCardsAddBlockParagraphImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/PostType/post-type-cards-how-to-add-block-paragraph.png";
import PostTypeToolbarImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/PostType/post-type-toolbar.png";
import PostTypeToolbarAlignImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/PostType/post-type-toolbar-align.png";
import PostTypeToolbarDragImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/PostType/post-type-toolbar-drag.png";
import PostTypeToolbarMoveImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/PostType/post-type-toolbar-move.png";
import PostTypeToolbarOptionsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/PostType/post-type-cards-toolbar-options.png";
import PostTypeToolbarResetPostTypeImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/PostType/post-type-toolbar-reset-post-type.png";
import PostTypeTransformImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/PostType/post-type-toolbar-transform.png";
import PostTypeCardsSettingsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/PostType/post-type-cards-settings.png";
import PostTypeSettingsFollowUpActionImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/PostType/post-type-settings-follow-up-action.png";
import PostTypeCardsSettingsLayoutImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/PostType/post-type-cards-settings-layout.png";
import * as React from 'react';
export default {
  AddBlock,
  SettingsAdvanced,
  Settings,
  Toolbar,
  PostTypeAddBlockImage,
  PostTypeCardsAddBlockParagraphImage,
  PostTypeToolbarImage,
  PostTypeToolbarAlignImage,
  PostTypeToolbarDragImage,
  PostTypeToolbarMoveImage,
  PostTypeToolbarOptionsImage,
  PostTypeToolbarResetPostTypeImage,
  PostTypeTransformImage,
  PostTypeCardsSettingsImage,
  PostTypeSettingsFollowUpActionImage,
  PostTypeCardsSettingsLayoutImage,
  React
};