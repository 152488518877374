import React from "react";

export const htmlAnchor = () => (
  <p>
    <b>HTML anchor</b> allows you to make a unique web address for a particular
    block. Then, you’ll be able to link directly to a block of your page. For
    more detail, refer to Page Jumps.
  </p>
);

export const cssClasses = () => (
  <p>
    <b>Additional CSS class(es)</b> lets you add CSS class(es) to your block,
    allowing you to write custom CSS and style the block as you see fit.
  </p>
);

export const modifyQuery = () => (
  <p>
    <b>Modify wordpress query manually</b> - opis
  </p>
);

export const linkRel = () => (
  <p>
    <b>Link rel</b> - opis
  </p>
);

export const htmlEl = () => (
  <p>
    <b>HTML element</b> - opis
  </p>
);
