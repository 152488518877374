import { AddBlock } from "../../../../src/components/Blocks/common/statik/AddBlock/AddBlock";
import { SettingsAdvanced } from "../../../../src/components/Blocks/common/statik/Settings/components/SettingsAdvanced";
import { Settings } from "../../../../src/components/Blocks/common/statik/Settings/Settings";
import { Toolbar } from "../../../../src/components/Blocks/common/statik/Toolbar/Toolbar";
import { Description } from "../../../../src/components/Blocks/common/statik/Description/Description";
import AccordionAddBlockImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-how-to-add-block.png";
import AccordionAddBlockParagraphImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-how-to-add-block-paragraph.png";
import AccordionSettingsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-settings.png";
import AccordionHeaderSettingsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-header-settings.png";
import AccordionContentSettingsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-content-settings.png";
import AccordionItemSettingsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-item-settings.png";
import AccordionToolbarImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-toolbar.png";
import AccordionToolbarAddItemImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-toolbar-add-item.png";
import AccordionToolbarAlignImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-toolbar-align.png";
import AccordionToolbarDragImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-toolbar-drag.png";
import AccordionToolbarMoveImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-toolbar-move.png";
import AccordionToolbarOptionsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-toolbar-options.png";
import AccordionToolbarSelectItemImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-toolbar-select-item.png";
import AccordionToolbarTransformImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-toolbar-transform.png";
import AccordionItemToolbarImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-item-toolbar.png";
import AccordionItemToolbarAddItemImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-item-toolbar-add-item.png";
import AccordionItemToolbarBoldImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-item-toolbar-bold.png";
import AccordionItemToolbarDeleteItemImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-item-toolbar-delete-item.png";
import AccordionItemToolbarDragImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-item-toolbar-drag.png";
import AccordionItemToolbarItalicImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-item-toolbar-italic.png";
import AccordionItemToolbarLinkImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-item-toolbar-link.png";
import AccordionItemToolbarMoreImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-item-toolbar-more.png";
import AccordionItemToolbarMoveImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-item-toolbar-move.png";
import AccordionItemToolbarOptionsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-item-toolbar-options.png";
import AccordionItemToolbarSelectAccordionImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-item-toolbar-select-accordion.png";
import AccordionItemToolbarSelectItemImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/Accordion/accordion-item-toolbar-select-item.png";
import * as React from 'react';
export default {
  AddBlock,
  SettingsAdvanced,
  Settings,
  Toolbar,
  Description,
  AccordionAddBlockImage,
  AccordionAddBlockParagraphImage,
  AccordionSettingsImage,
  AccordionHeaderSettingsImage,
  AccordionContentSettingsImage,
  AccordionItemSettingsImage,
  AccordionToolbarImage,
  AccordionToolbarAddItemImage,
  AccordionToolbarAlignImage,
  AccordionToolbarDragImage,
  AccordionToolbarMoveImage,
  AccordionToolbarOptionsImage,
  AccordionToolbarSelectItemImage,
  AccordionToolbarTransformImage,
  AccordionItemToolbarImage,
  AccordionItemToolbarAddItemImage,
  AccordionItemToolbarBoldImage,
  AccordionItemToolbarDeleteItemImage,
  AccordionItemToolbarDragImage,
  AccordionItemToolbarItalicImage,
  AccordionItemToolbarLinkImage,
  AccordionItemToolbarMoreImage,
  AccordionItemToolbarMoveImage,
  AccordionItemToolbarOptionsImage,
  AccordionItemToolbarSelectAccordionImage,
  AccordionItemToolbarSelectItemImage,
  React
};