import { AddBlock } from "../../../../src/components/Blocks/common/statik/AddBlock/AddBlock";
import { SettingsAdvanced } from "../../../../src/components/Blocks/common/statik/Settings/components/SettingsAdvanced";
import { Settings } from "../../../../src/components/Blocks/common/statik/Settings/Settings";
import { Toolbar } from "../../../../src/components/Blocks/common/statik/Toolbar/Toolbar";
import UniversalBlockAddBlockImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/UniversalBlock/universal-block-how-to-add-block.png";
import UniversalBlockAddBlockParagraphImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/UniversalBlock/universal-block-how-to-add-block-paragraph.png";
import UniversalBlockToolbarImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/UniversalBlock/universal-block-toolbar.png";
import UniversalBlockToolbarTransformImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/UniversalBlock/universal-block-toolbar-transform.png";
import UniversalBlockToolbarDragImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/UniversalBlock/universal-block-toolbar-drag.png";
import UniversalBlockToolbarMoveImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/UniversalBlock/universal-block-toolbar-move.png";
import UniversalBlockToolbarOptionsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/UniversalBlock/universal-block-toolbar-options.png";
import UniversalBlockSettingsImage from "../../../../content/Statik JAM Hosting/3. Blocks/images/UniversalBlock/universal-block-settings.png";
import * as React from 'react';
export default {
  AddBlock,
  SettingsAdvanced,
  Settings,
  Toolbar,
  UniversalBlockAddBlockImage,
  UniversalBlockAddBlockParagraphImage,
  UniversalBlockToolbarImage,
  UniversalBlockToolbarTransformImage,
  UniversalBlockToolbarDragImage,
  UniversalBlockToolbarMoveImage,
  UniversalBlockToolbarOptionsImage,
  UniversalBlockSettingsImage,
  React
};