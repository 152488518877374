import React from "react";
import { WrapperImageSmall, Wrapper } from "../../wrappers/Wrappers.style";

export const Settings = (props) => {
  const { settingsImage, settingsIntroductionText, settingsDescription } =
    props;

  return (
    <Wrapper>
      {settingsIntroductionText}
      <WrapperImageSmall>
        <img src={settingsImage} />
      </WrapperImageSmall>
      {settingsDescription}
    </Wrapper>
  );
};
